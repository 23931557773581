import { defineComponent, ref, Ref } from '@vue/composition-api';

import Card from '@/components/Card';
import FetchData from '@/components/FetchData';

import MusicSongList from '@/components/Article/MusicSongList';

export default defineComponent({
	components: { Card, FetchData, MusicSongList },
	setup() {
		const everydaySongList: Ref<any[]> = ref([
			{
				onlyid: 123456,
				title: '12124123123',
				author: '32423786542365782',
				duration: -1,
			},
		]);
		const touchData = (data: any) => {
			everydaySongList.value = data;
		};
		return { everydaySongList, touchData };
	},
});
